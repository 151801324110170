import React from 'react';
import NextLink from 'next/link';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Link,
  useDisclosure
} from '@chakra-ui/react';
import { PURPLE_BG_PAGES, URLS } from '@lib';
import MenuIcon from 'remixicon-react/Menu3FillIcon';
import { openPopupWidget } from 'react-calendly';
import { LayoutContainer } from '../../common/layout-container';
import { MobileMenu } from './mobile-menu';
import RadixMenu from '../radix-menu';

const { home } = URLS;
const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL || '';

// Ensure the logo and CTAs have same width, so that `MegaMenu` is centred.
const LOGO_AND_CTAS_WIDTH = '260px';
export const HEADER_HEIGHT = '80px';

export function Header(): JSX.Element | null {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const { pathname } = useRouter();

  React.useEffect(() => {
    onClose();
  }, [pathname]);
  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const logo = hasPurpleBg
    ? '/images/clerk-logo-dark.svg'
    : '/images/clerk-logo.svg';


  const blogLogo =
    pathname.includes('/blog') && hasPurpleBg
      ? '/images/blog-dark.svg'
      : '/images/blog.svg';

  const bg = hasPurpleBg
    ? 'transparent'
    : !hasScrolled
    ? 'transparent'
    : 'gray.25';


  const textColor = hasPurpleBg ? 'white' : 'gray.700';
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 50 && !hasScrolled) {
          setHasScrolled(true);
        } else if (scrollY <= 50 && hasScrolled) {
          setHasScrolled(false);
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [hasScrolled]);

  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  // Workaround to prevent `Hydration failed because the initial UI does not match what was rendered on the server.`
  if (!shouldRender) return null;

  return (
    <LayoutContainer
      verticalPadding="none"
      pos="fixed"
      bg={bg}
      top={0}
      w="full"
      borderBottom={hasScrolled ? '1px solid' : 'none'}
      borderColor="gray.200"
      shadow={hasScrolled ? 'base' : 'none'}
      transition="all 0.2s"
      zIndex="sticky"
    >
      <Flex
        as="header"
        justify="space-between"
        align="center"
        gap={6}
        h={HEADER_HEIGHT}
      >
        <Link href={home}>
          <a href={home}>
            <span className="sr-only">Clerk</span>
            <div className="image-wrap">
              {pathname.includes('/blog') ? (
                <Image
                  src={blogLogo}
                  height={98}
                  width={160}
                  alt="Blog"
                  priority
                />
              ) : (
                <Image
                  src={logo}
                  height={24}
                  width={77}
                  alt="Clerk logo"
                  priority
                />
              )}
            </div>
          </a>
        </Link>

        {/* Mobile */}
        <Box display={{ lg: 'none' }}>
          <Button aria-label="menu" variant="unstyled" _hover={{}}>
            <Icon
              as={MenuIcon}
              boxSize={6}
              color={textColor}
              onClick={onOpen}
            />
          </Button>
          <MobileMenu isOpen={isOpen} onClose={onClose} />
        </Box>

        {/* Desktop */}
        <>
          <Center flex="1" display={{ base: 'none', lg: 'block' }}>
            <RadixMenu textColor={textColor} />
          </Center>
          <Flex
            justify="flex-end"
            gap={5}
            w={LOGO_AND_CTAS_WIDTH}
            display={{ base: 'none', lg: 'flex' }}
            zIndex={100}
          >
            <SignedOut>
              {hasScrolled ? (
                <Button
                  variant="ghost"
                  onClick={() => {
                    openPopupWidget({
                      url: URLS.calendlyUrl
                    });
                  }}
                >
                  Book a demo
                </Button>
              ) : (
                <Link href={DASHBOARD_URL + '/sign-in'}>
                  <Button as="a" variant="ghost" color={textColor} _hover={{}}>
                    Sign in
                  </Button>
                </Link>
              )}

              <Link href={DASHBOARD_URL + '/sign-up'}>
                <Button as="a" variant="white">
                  Sign up
                </Button>
              </Link>
            </SignedOut>

            <SignedIn>
              <Link href={DASHBOARD_URL}>
                <Button as="a">Dashboard</Button>
              </Link>
            </SignedIn>
          </Flex>
        </>
      </Flex>
    </LayoutContainer>
  );
}
